<template>
  <div class="manage-privileges">
    <v-alert
      border="right"
      dismissible
      colored-border
      type="error"
      elevation="2"
      max-width="350px"
      class="ml-auto mt-2 mr-2"
      v-if="error"
    >
      {{ $t("__user_data_invalid") }}
    </v-alert>
    <v-card elevation="0">
      <v-card-title>
        {{ $t("__manageadminprivileges_title") }}
        <v-btn class="ml-auto primary" @click.stop="openAddDialog(true)">{{
          $t("__set_admin_privilege")
        }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-data-table
            :headers="dataTable.headers"
            :items="adminList"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:item.privilege="{ item }">
              <v-tooltip
                top
                v-for="privilege in item.privilege"
                :key="privilege.name"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="ma-2"
                    :color="privilege.color"
                    text-color="white"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-avatar left>
                      <v-icon v-if="!privilege.timeNonactived"
                        >mdi-checkbox-marked-circle</v-icon
                      >
                      <v-icon v-else>mdi-close-circle</v-icon>
                    </v-avatar>
                    {{ $t(`__${privilege.name}`) }}
                    <v-avatar right>
                      <v-icon x-small @click="openDeleteDialog(privilege)"
                        >mdi-close</v-icon
                      >
                    </v-avatar>
                  </v-chip>
                </template>
                {{ privilege.startTime }} ~ {{ privilege.stopTime }}
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      class="mr-5"
                      dark
                      @click.stop="openAddDialog(false, item)"
                    >
                      mdi-plus-thick
                    </v-icon>
                  </template>
                  <span>{{ $t("__set_admin_privilege") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                      dark
                      @click.stop="openDeleteDialog(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $t("__delete_admin_privilege") }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">
                {{ $t("__set_admin_privilege") }}
              </span>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-container>
                <template v-if="cacheUser.email">
                  <p class="text-subtitle-1 mb-0">{{ $t("__email") }}</p>
                  <p>{{ cacheUser.email }}</p>
                  <p class="text-subtitle-1 mb-0">{{ $t("__user_id") }}</p>
                  <p>{{ cacheUser.userID }}</p>
                </template>
                <template v-else>
                  <v-radio-group row v-model="addMethod">
                    <v-radio :label="$t('__email')" value="email"></v-radio>
                    <v-radio :label="$t('__user_id')" value="id"></v-radio>
                  </v-radio-group>
                  <v-text-field
                    class="pt-0"
                    clearable
                    :rules="[rules.required, rules.email]"
                    :label="$t('__email')"
                    v-model="input.email"
                    v-if="addMethod === 'email'"
                  >
                  </v-text-field>
                  <v-text-field
                    class="pt-0"
                    clearable
                    counter
                    maxlength="28"
                    :rules="[rules.required, rules.counter, rules.numAndEg]"
                    :label="$t('__user_id')"
                    v-model="input.userID"
                    v-if="addMethod === 'id'"
                  >
                  </v-text-field>
                </template>
                <v-select
                  :items="privilegeName"
                  item-text="ch"
                  item-value="eg"
                  :label="$t('__admin_privileges')"
                  required
                  v-model="input.privilege"
                >
                </v-select>
                <v-text-field
                  :label="$t('__start_time')"
                  type="date"
                  required
                  v-model="input.setStartTime"
                >
                </v-text-field>
                <span>{{ $t("__stop_time") }}</span>
                <v-radio-group column v-model="input.radio" class="my-0">
                  <v-radio
                    :label="$t('__unlimited_time')"
                    value="unlimited"
                  ></v-radio>
                  <div class="d-flex align-center justify-start">
                    <v-radio class="mr-0" value="days"> </v-radio>
                    <v-text-field
                      class="mt-0 pt-0 day-input-width"
                      type="number"
                      v-model="input.days"
                      :disabled="input.radio === 'unlimited'"
                    ></v-text-field>
                    <span>{{ $t("__input_days") }}</span>
                  </div>
                </v-radio-group>
              </v-container>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                {{ $t("__cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  dialog = false;
                  confirmAdd();
                "
              >
                {{ $t("__confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" persistent max-width="350px">
          <v-card>
            <v-card-title>
              <p class="text-h5 red--text">
                <v-icon color="red">mdi-alert-circle-outline</v-icon>
                {{ $t("__confirm_delete_privilege") }}
              </p>
              <p v-if="cacheUser.adminName">
                {{ $t("__first_name") + ": " }}{{ cacheUser.adminName }}
              </p>
              <p v-else-if="cacheUser.name">
                {{ $t("__admin_privileges") + ": " }}
                {{ $t(`__${cacheUser.name}`) }}
              </p>
            </v-card-title>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="deleteDialog = false">
                {{ $t("__cancel") }}
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="
                  deleteDialog = false;
                  confirmDelete();
                "
              >
                {{ $t("__confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { adminAPI } from "@/plugins/service";
import { format } from "date-fns";

export default {
  name: "ManagePrivileges",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    }),
    adminList() {
      const list = [];
      for (const userID of this.userIDs) {
        const adminIDs = this.adminPrivilegeIDs[userID];
        const adminForm = this.adminPrivileges[userID];
        const userData = this.users[userID];
        if (userData !== null) {
          list.push({
            email: userData.email,
            adminName: `${userData.last_name} ${userData.first_name}`,
            privilege: [],
            userID: userID
          });
        }
        for (const adminID of adminIDs) {
          list.forEach(item => {
            if (adminID.split("-")[0] == item.userID) {
              item.privilege.push({
                name: adminForm[adminID].name,
                startTime: this.formatTime(adminForm[adminID].start_time),
                stopTime: this.formatTime(adminForm[adminID].stop_time),
                timeNonactivated: false,
                userID: item.userID
              });
            }
          });
        }
      }
      return list;
    }
  },
  data() {
    return {
      adminPrivilegeIDs: {},
      adminPrivileges: {},
      userIDs: [],
      users: {},
      cacheUser: {},
      dialog: false,
      deleteDialog: false,
      isNew: false,
      error: false,
      input: {
        privilege: "user-management",
        setStartTime: format(new Date(), "yyyy-MM-dd"),
        radio: "unlimited",
        days: "",
        userID: "",
        email: ""
      },
      addMethod: "email",
      IDparam: "",
      rules: {
        required: value => !!value || this.$t("__required"),
        counter: value => value.length == 28 || this.$t("__ID_invalid"),
        numAndEg: value => {
          const pattern = /^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/;
          return pattern.test(value) || this.$t("__ID_invalid");
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("__email_address_invalid");
        }
      },
      privilegeName: [
        { ch: this.$t("__master"), eg: "master" },
        { ch: this.$t("__developer"), eg: "developer" },
        { ch: this.$t("__admin-management"), eg: "admin-management" },
        { ch: this.$t("__user-management"), eg: "user-management" }
      ],
      dataTable: {
        headers: [
          {
            text: this.$t("__email"),
            sortable: false,
            value: "email"
          },
          {
            text: this.$t("__first_name"),
            sortable: false,
            value: "adminName"
          },
          {
            text: this.$t("__admin_privileges"),
            align: "start",
            sortable: false,
            value: "privilege"
          },
          { text: this.$t("__setting"), sortable: false, value: "actions" }
        ]
      }
    };
  },
  methods: {
    loadUsersAdminPrivileges() {
      return this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.getUsersAdminPrivileges(idToken);
        })
        .then(result => {
          this.$store.dispatch("setIsLoading", false);
          this.adminPrivilegeIDs = result.admin_privilege_ids;
          this.adminPrivileges = result.admin_privileges;
          this.userIDs = result.user_ids;
          this.users = result.users;
          this.privilegeChip();
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    getUserData(email) {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.getUser(idToken, "", email);
        })
        .then(result => {
          this.$store.dispatch("setIsLoading", false);
          this.IDparam = result.user_id;
          this.addPrivilege();
          this.error = false;
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.error = true;
        });
    },
    openAddDialog(isNew, item) {
      this.dialog = true;
      if (!isNew) {
        this.cacheUser = item;
        this.isNew = false;
      } else {
        this.cacheUser = {};
        this.isNew = true;
      }
      this.input = {
        privilege: "user-management",
        setStartTime: format(new Date(), "yyyy-MM-dd"),
        radio: "unlimited",
        days: "",
        userID: "",
        email: ""
      };
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.cacheUser = item;
    },
    confirmDelete() {
      this.$store.dispatch("setIsLoading", true);
      let privilegeAry = this.cacheUser.privilege;
      if (!privilegeAry) {
        this.deletePrivilege();
      } else {
        privilegeAry.forEach(item => {
          this.deletePrivilege(item.name);
        });
      }
    },
    confirmAdd() {
      if (this.isNew && this.addMethod === "email") {
        this.getUserData(this.input.email);
      } else if (this.isNew && this.addMethod === "id") {
        this.IDparam = this.input.userID;
        this.addPrivilege();
      } else if (!this.isNew) {
        this.IDparam = this.cacheUser.userID;
        this.addPrivilege();
      }
    },
    addPrivilege() {
      this.$store.dispatch("setIsLoading", true);
      let stopTimeparam = "";
      let startTimeparam = "";
      startTimeparam = new Date(this.input.setStartTime)
        .toISOString()
        .replace(/Z/g, "+08:00");
      if (this.input.radio == "unlimited") {
        stopTimeparam = "0001-01-01T00:00:00Z";
      } else if (this.input.radio == "days") {
        let cacheTime = Math.floor(new Date(this.input.setStartTime) / 1000);
        cacheTime += this.input.days * 86400;
        stopTimeparam = new Date(cacheTime * 1000)
          .toISOString()
          .replace(/Z/g, "+08:00");
      }
      return this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.addAdminPrivilege(
            idToken,
            this.IDparam,
            this.input.privilege,
            startTimeparam,
            stopTimeparam
          );
        })
        .then(result => {
          this.$store.dispatch("setIsLoading", false);
          console.log(result);
          this.loadUsersAdminPrivileges();
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    deletePrivilege(privilegeParam = this.cacheUser.name) {
      return this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.deleteAdminPrivilege(
            idToken,
            this.cacheUser.userID,
            privilegeParam
          );
        })
        .then(result => {
          console.log(result);
          this.loadUsersAdminPrivileges();
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd HH:mm:ss");
    },
    privilegeChip() {
      this.adminList.forEach(item => {
        item.privilege.forEach(privilegeItem => {
          let time = Math.floor(new Date(privilegeItem.stopTime) / 1000);
          let today = Math.floor(new Date() / 1000);
          if (time < today) {
            privilegeItem.timeNonactivated = true;
          } else {
            privilegeItem.timeNonactivated = false;
          }
          switch (privilegeItem.name) {
            case "master":
              privilegeItem.color = "primary";
              break;
            case "developer":
              privilegeItem.color = "accent";
              break;
            case "admin-management":
              privilegeItem.color = "success";
              break;
            case "user-management":
              privilegeItem.color = "warning";
              break;
            default:
              break;
          }
        });
      });
    }
  },
  created() {
    this.$store.dispatch("setIsLoading", true);
    this.loadUsersAdminPrivileges();
  }
};
</script>
<style scoped>
.v-input .day-input-width {
  max-width: 15%;
}
</style>
